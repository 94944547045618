<template>
    <div class="Buyer">
        <!-- <Header/> -->
        <BannerBuyServer/>
        <Registration id="RegistrationOfExhibitors"/>
        <Scope id="ScopeOfExhibition"/>
        <Exhibitorsdirectory id="ExhibitorsDirectory"/>
        <Transportations id="TransportationAccommodation"/>
        <Footer/>
    </div>
</template>
<script>
import Header   from '../../components/header' //头部
import BannerBuyServer from './children/bannerBuyserver'  //背景图
import Registration from './children/Registration'  //参展登记 
import Scope from './children/Scope' // 参展范围
import Exhibitorsdirectory from './children/Exhibitorsdirectory' //展商名录
import Transportations from '../exhibitorsService/children/Transportations' //交通住宿
import Footer from '../home/children/Footer' //底部
export default {
    components:{
        Header,
        BannerBuyServer,
        Registration,
        Scope,
        Exhibitorsdirectory,
        Transportations,
        Footer
    },
    data(){
        return{
            
        }
    },
}
</script>
<style lang="less" scoped>


</style>