<template>
    <div class="exhibits">
        <div class="title-container">
            <div class="title">
                <h2>{{$t('ScopeExhibits.Title')}}</h2>
            </div>
        </div>
        <div class="warp_contina">
            <div class="left">
                <img src="/images/2B18837D.jpg" alt/>
                <div class="introduces">
                    <p> {{$t('ScopeExhibits.Introduces')}}</p>
                </div>
            </div>
            <div class="right">
                <div class="right_top">
                    <ul class="toplist">
                        <li class="toplist-item list">
                            <div class="item">
                                <div class="show-item">
                                    <div class="warp">
                                        <div class="imgurl">
                                            <img src="/images/498AA914.png" alt/>
                                        </div>
                                        <h1>{{$t('ScopeExhibits.Equipment.EquipmentType0')}}</h1>
                                        <p>{{$t('ScopeExhibits.Equipment.EquipmentContent0')}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="toplist-item list">
                            <div class="item">
                                <div class="show-item">
                                    <div class="warp">
                                        <div class="imgurl">
                                            <img src="/images/DDC93373.png" alt/>
                                        </div>
                                        <h1>{{$t('ScopeExhibits.Equipment.EquipmentType1')}}</h1>
                                        <p>{{$t('ScopeExhibits.Equipment.EquipmentContent1')}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="toplist-item list">
                            <div class="item">
                                <div class="show-item">
                                    <div class="warp">
                                        <div class="imgurl">
                                            <img src="/images/AA5F7332.png" alt/>
                                        </div>
                                        <h1>{{$t('ScopeExhibits.Equipment.EquipmentType2')}}</h1>
                                        <p>{{$t('ScopeExhibits.Equipment.EquipmentContent2')}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="toplist-item toplist-bottom-item list">
                            <div class="item">
                                <div class="show-item">
                                    <div class="warp">
                                        <div class="imgurl">
                                            <img src="/images/22DE039F.png" alt/>
                                        </div>
                                        <h1>{{$t('ScopeExhibits.Equipment.EquipmentType3')}}</h1>
                                        <p>{{$t('ScopeExhibits.Equipment.EquipmentContent3')}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="toplist-item toplist-bottom-item list">
                            <div class="item">
                                <div class="show-item">
                                    <div class="warp">
                                        <div class="imgurl">
                                            <img src="/images/B1083DF2.png" alt/>
                                        </div>
                                        <h1>{{$t('ScopeExhibits.Equipment.EquipmentType4')}}</h1>
                                        <p>{{$t('ScopeExhibits.Equipment.EquipmentContent4')}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="toplist-item toplist-bottom-item list">
                            <div class="item">
                                <div class="show-item">
                                    <div class="warp">
                                        <div class="imgurl">
                                            <img src="/images/xsadr12d.png" alt/>
                                        </div>
                                        <h1>{{$t('ScopeExhibits.Equipment.EquipmentType5')}}</h1>
                                        <p>{{$t('ScopeExhibits.Equipment.EquipmentContent5')}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    .exhibits {
        .title-container {
            /*padding-top: px2rem(20);*/
            $width: px2rem((374 + 38) * 2);
            width: calc(#{$width} + 496px);
            margin: 0 auto;
        }
    }

    .title {
        @include titles();

        line-height: 32px;
        margin-bottom: px2rem(48);

        h2 {
            font-size: 26px;
            font-weight: 600;
            color: #333;
        }
    }

    .header {
        width: 70%;
        margin: auto;
        padding-top: 100px;

        .titles {
            @include titles();

            line-height: 32px;
            margin-bottom: 42px;
            padding-bottom: 3px;

            h3 {
                font-size: 26px;
                font-weight: 600;
                color: #333333;
            }
        }
    }

    .warp_contina {
        width: 100%;
        position: relative;
        overflow: hidden;

        .left {
            width: 42%;
            float: left;
            height: 732px;

            @include sm {
                height: auto;
            }

            position: relative;
            z-index: 20;

            img {
                width: 100%;
                height: 100%;
            }

            .introduces {
                @include scopeOfExhibitors();
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);

            }
        }

        .right {
            width: 58%;
            float: left;
            overflow: hidden;

            .right_top {
                width: 100%;
            }

            .right_bottom {
                width: 100%;
                float: left;

                .bottom_list {
                    width: 100%;
                    float: left;
                    display: flex;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;

                    .list_items {
                        width: 33.00%;
                        height: 303px;

                        .warp {
                            .item {
                                width: 390px;
                                height: 303px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .toplist-item {
        width: 33.33333%;
        height: 430px;
        overflow: hidden;
        float: left;

        $imageList: (
                '5FFA2274.jpg',
                'D1A82BDE.jpg',
                'C78AFE85.jpg',
                '5F5CDF54.jpg',
                '2BEBDD46.jpg',
                'xgh45Hg.jpg',
        );


        @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
                background: url('/images/#{nth($imageList,$i)}') no-repeat center;
                background-size: cover;
            }
        }

        .item {
            position: relative;
            width: 100%;
            height: 100%;

            .show-item {
                position: absolute;
                left: 0;
                top: 100%;
                margin-top: -60px;

                background: rgba(250, 210, 20, 1);
                opacity: 0.9;
                width: 100%;
                height: 430px;
                transition: all 0.3s ease-out;

                .warp {
                    width: 100%;
                    height: 430px;
                    display: inline-block;

                    .imgurl {
                        width: 117px;
                        height: 118px;
                        margin: 44px auto 0;
                        opacity: 0;
                        transition: .2s linear;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    h1 {
                        position: absolute;
                        //   top: 192px;
                        top: 20px;
                        left: 20px;
                        font-size: 18px;
                        font-weight: 600;
                        color: rgba(51, 51, 51, 1);
                        transition: all 0.2s ease-out;

                    }

                    p {
                        width: 100%;
                        box-sizing: border-box;
                        height: 73px;
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        margin-top: 50px;
                        display: inline-block;
                        padding-left: 20px;
                    }
                }
            }
        }

        &:hover {
            .show-item {
                top: 0;
                margin-top: 0;

                .warp {
                    h1 {
                        top: 178px;
                    }

                    .imgurl {
                        opacity: 1;
                    }
                }
            }
        }

        &.toplist-bottom-item {
            height: 302px;
        }
    }

    @include md {
        .exhibits {
            .title-container {
                width: 9rem;
                position: relative;
            }
        }

        .warp_contina {
            .left, .right {
                float: none;
                width: 100%;
            }
        }
        .toplist-item {
            width: 33.333%;
        }
    }

    @include sm {
        .exhibits {
            .title-container {
                width: 9rem;
                position: relative;
            }
        }
        .warp_contina {

        }

        .toplist-item {
            width: 100%;
            float: none;

            .imgurl {
                display: none;
            }

            .item .show-item {
                top: 70%;
                margin-top: 0;
            }

            .item .show-item .warp h1 {
                top: 20px;
            }

            p {
                padding-right: 10px;
            }
        }

        .toplist-item:last-child {
            display: none;
        }
    }
</style>