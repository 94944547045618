<template>
    <div class="exhibition" :style="bgimg">
        <div class="Newedition">
            <div class="titles">
                <h3 style="font-size: 26px;
        font-weight: 600;
        color: #333333;">{{$t('Newedition.Title')}}</h3>
            </div>
        </div>
        <div class="Mainbox">
            <div class="Technological">
                <ul class="piclist">
                    <li class="list_item">
                        <img src="/images/Buyerservice/bijiben.png" alt/>
                        <p style=" ">{{$t('Newedition.Technological.Item0')}}</p>
                    </li>
                    <li class="list_item">
                        <img src="/images/Buyerservice/kefu.png" alt/>
                        <p>{{$t('Newedition.Technological.Item1')}}</p>
                    </li>
                    <li class="list_item">
                        <img src="/images/Buyerservice/shijian.png" alt/>
                        <p>{{$t('Newedition.Technological.Item2')}}</p>
                    </li>
                </ul>
            </div>
            <div class="Progressbar">
                <div class="Realization"></div>
                <div class="imgUrl" style="margin:0 26px">
                    <img src="/images/Exhibitorsservice/icon_gou_disabled@2x.png" alt/>
                </div>
                <div class="Dottedline"></div>
                <div style="margin:0 26px" class="imgUrl">
                    <img src="/images/Exhibitorsservice/icon_gou_disabled@2x.png" alt/>
                </div>
                <div class="Dottedline"></div>
                <div style="margin:0 26px" class="imgUrl">
                    <img src="/images/Exhibitorsservice/icon_gou_disabled@2x.png" alt/>
                </div>
                <div class="Dottedlines"></div>
            </div>
            <div class="Applications" :style="zgimg">
                <keep-alive>
                    <div class="box-ssing">
                        <div class="label-root">
                            <label for="Contacts">
                                <div class="label-container">
                                    <span>* {{$t('Newedition.Contacts')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="Contacts"
                                               v-model="application.contacts" class="input"
                                               :class="{showborder:contacts == true}"
                                               @blur="changeNumContacts">
                                        <div class="after"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="label-root">
                            <label for="Mobilephone">
                                <div class="label-container">
                                    <span>{{$route.params.lang == 'zh' ? '*' : ''}} {{$t('ApplicationExhibition.Mobilephone')}}</span>

                                    <div class="input-container">
                                        <input type="text" name="" id="Mobilephone"
                                               v-model="application.mobilephone" class="input"
                                               :class="{showborder:mobilephone == true}"
                                               @blur="changeNumMobilephone">
                                        <div class="after"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="label-root" v-if="$route.params.lang == 'en'">
                            <label for="mailbox">
                                <div class="label-container">
                                    <span>* {{$t('ApplicationExhibition.Mailbox')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="mailbox"
                                               v-model="application.mailbox"
                                               class="input"
                                               :class="{showborder:thismailbox == true}"
                                               @blur="changemailbox">
                                        <div class="after"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="label-root">
                            <label for="VerificationCode" class="VerificationCode">
                                <div class="label-container">
                                    <span>* {{$t('Newedition.VerificationCode')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="VerificationCode"
                                               v-model="application.verificationCode" class="VerificationCode input"
                                               :class="{showborder:verificationCode == true}"
                                               @blur="changeNumVerificationCode">
                                        <div class="after"></div>
                                    </div>
                                </div>
                                <input type="button"
                                       class="btn input"
                                       v-model="codeMsg"
                                       @click="Verification"
                                       :disabled="codeDisabled"/>
                            </label>
                        </div>
                        <div class="label-root" v-if="$route.params.lang == 'zh'">
                            <label for="mailbox">
                                <div class="label-container">
                                    <span> {{$t('ApplicationExhibition.Mailbox')}}</span>
                                    <div class="input-container">
                                        <input type="text" name="" id="mailbox"
                                               v-model="application.mailbox"
                                               class="input"
                                               :class="{showborder:thismailbox == true}"
                                               @blur="changemailbox">
                                        <div class="after"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="Apply" @click="ApplicationExhibition">
                            {{$t('Newedition.Apply')}}
                        </div>
                    </div>
                </keep-alive>
            </div>
        </div>
    </div>
</template>
<script>
    import {Toast} from 'vant';
    import {registerAudience} from "@/publicApi/audience";
    import {registersendCode} from "@/publicApi/audience";
    import md5 from "md5";

    export default {
        data() {
            return {
                bgimg: {        //背景图
                    backgroundImage: "url(/images/Buyerservice/bgtu.png),url(/images/Buyerservice/bgdiy.png)",
                    backgroundRepeat: "no-repeat,no-repeat",

                },
                zgimg: {
                    backgroundImage: "url(/images/Exhibitorsservice/bg_ditu_2@2x.png)",
                    backgroundRepeat: "no-repeat",
                },
                application: {
                    contacts: '',//联系人
                    mobilephone: '',//手机
                    verificationCode: '',//验证码
                    mailbox: '',//邮箱
                },
                codeDisabled: false,
                countdown: 60,
                codeMsg: this.$t('Newedition.getValidateCode'),
                timer: null,

                contacts: false, //联系人
                mobilephone: false, //手机
                verificationCode: false, //验证码
                valscode: false, //点击验证码
                thismailbox: false,
            }
        },
        methods: {
            getApplicationExhibition() {
                let reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
                let mailbox = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (this.application.contacts === '') {
                    this.contacts = true
                    Toast('联系人不能为空')
                } else if (this.application.mobilephone === '' && this.$route.params.lang === 'zh') {
                    this.thismobilephone = true
                    Toast('手机号码不能为空')
                } else if (this.application.mailbox === '' && this.$route.params.lang === 'en') {
                    this.thismailbox = true;
                    Toast('邮箱不能为空')
                } else if (this.application.mailbox !== '' && !mailbox.test(this.application.mailbox)) {
                    this.thismailbox == true
                    Toast('你输入的邮箱不正确')
                } else if (!reg.test(this.application.mobilephone) && this.$route.params.lang === 'zh') {
                    this.mobilephone = true
                    Toast('你的手机号码有误请重新输入')
                } else if (this.valscode == false) {
                    this.verificationCode = true
                    Toast('请先获取验证码')
                } else {
                    return true
                }

            }, //判断表单
            //提交表单
            ApplicationExhibition() {
                if (this.getApplicationExhibition()) {
                    this.getCurrentApplicationexhibition()
                }
            },
            //提交表内容
            async getCurrentApplicationexhibition() {
                const channel = localStorage.getItem('conmart.channel') || "";
                let res = await registerAudience({
                    code: this.application.verificationCode,
                    channel: channel,
                    email: this.application.mailbox,
                    lang: this.$transformAPILang(),
                    mobile: this.application.mobilephone,
                    name: this.application.contacts,
                    expo: 'km2020'
                })
                if (res.status == 2 && res.data == null) {
                    Toast('你好！ 你的手机号 已经申请了 请勿重复提交')
                }
                if (res.status == -1 && res.data == null) {
                    console.log('验证码格式有误')
                }
                if (res.status == 2 && res.data == false) {
                    Toast('你输入的验证码有误 请重新获取')
                }
                if (res.status == 0 && res.data == true) {
                    Toast('恭喜你 提交成功')
                    this.Clearinputbox()
                }
            },
            //获取验证码
            async getCurrentApplicationesendCode() {
                const isZH = this.$route.params.lang === 'zh';
                let target = isZH ? this.application.mobilephone : this.application.mailbox;
                let n = ~~(Math.random() * 10);
                let time = Date.now();
                let code = `${n}${md5(`${n}${time}${target}`)}`;
                let res = await registersendCode({
                    code: code,
                    target: target,
                    time: time,
                    type: isZH ? 'Sms' : 'Email',
                    key: 'AudienceRegister'
                });
                if (res.status == 0 && res.data == true) {
                    Toast(isZH ? '验证码发送成功，请注意查看你手机' : this.$t('邮件发送成功，请注意查看你的邮件'));
                }
            },
            //清空输入框
            Clearinputbox() {
                this.application.contacts = ''
                this.application.mobilephone = ''
                this.application.verificationCode = '',
                    this.application.mailbox = ''
            },
            validateBtn() {
                if (!this.timer) {
                    this.timer = setInterval(() => {
                        if (this.countdown > 0 && this.countdown <= 60) {
                            this.countdown--;
                            if (this.countdown !== 0) {
                                this.codeMsg = "重新发送(" + this.countdown + ")";
                            } else {
                                clearInterval(this.timer);
                                this.codeMsg = "获取验证码";
                                this.countdown = 60;
                                this.timer = null;
                                this.codeDisabled = false;
                            }
                        }
                    }, 1000)
                }
            },//倒计时
            validataaction() {
                if (this.$route.params.lang === 'zh') {
                    let reg = /^1[3|4|5|7|8|9][0-9]{9}$/;
                    if (this.application.mobilephone === '') {
                        Toast(this.$t('手机号码不能为空'));
                    } else if (!reg.test(this.application.mobilephone)) {
                        Toast('你的手机号码有误请重新输入');
                    } else {
                        return true
                    }
                } else {
                    let mailbox = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if (this.application.mailbox === '') {
                        Toast('邮箱不得为空');
                    } else if (!mailbox.test(this.application.mailbox)) {
                        Toast(this.$t('你的邮箱有误请重新输入'));
                    } else {
                        return true;
                    }
                }
            },//手机号验证规则
            //发送验证码
            Verification() {
                if (this.validataaction()) {
                    this.validateBtn();
                    this.getCurrentApplicationesendCode()
                }
                this.valscode = true
            },
            changeNumContacts() {
                this.contacts = false
            },
            changeNumMobilephone() {
                this.mobilephone = false
            },
            changeNumVerificationCode() {
                this.verificationCode = false
            },
            changemailbox() {
                this.thismailbox = false
            }
        }
    }
</script>

<style lang="scss" scoped>

    .exhibition {
        width: 100%;
        /*height: 800px;*/
    }

    .Newedition {
        @include design-width-setting();
        margin: auto;
        padding-top: 10px;
        box-sizing: border-box;
    }

    .titles {
        @include titles();

        line-height: 32px;
        margin-bottom: 44px;
        padding-bottom: 3px;

        /*padding-top: 80px;*/

        h1 {
            font-size: 26px;
            font-weight: 500;
            color: #333333;
        }
    }


    .Mainbox {
        width: 100%;
        /*min-height: 600px;*/
        background: #fff;
    }

    .piclist {
        padding: 60px 42px 0 42px;
        box-sizing: border-box;
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        justify-content: space-between;

        .list_item {
            text-align: center;
            margin: auto;

            img {
                width: px2rem(160);
                height: px2rem(160);
                max-width: 80px;
                max-height: 80px;
                min-width: 40px;
                min-height: 40px;
            }

            p {
                margin-top: 8px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
        }
    }

    .Progressbar {
        margin: 10px 42px 0;
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            border-bottom: 2px dashed #D8D8D8;

        }

        .imgUrl {
            width: 33.3333%;
            height: px2rem(160);
            max-height: 80px;
            min-height: 30px;
            display: list-item;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            text-align: center;

            &:before {
                content: '';
                position: absolute;
                width: px2rem(80);
                height: px2rem(80);
                display: block;
                background: #fff;
                top: 50%;
                left: 50%;
                margin-left: px2rem(-40);
                margin-top: px2rem(-40);
                z-index: 0;
            }

            &:after {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                content: '';
            }

            img {
                width: px2rem(60);
                height: px2rem(60);
                max-width: 30px;
                max-height: 30px;
                min-width: 20px;
                background: #fff;
                min-height: 20px;
                vertical-align: middle;
                position: relative;
                z-index: 1;
            }
        }
    }

    .Applications {
        width: 100%;
        background-position-x: 785px;
        background-position-y: 31px;

        .box-ssing {
            margin: auto;
            box-sizing: border-box;
            position: relative;
            text-align: center;

            .label-root {
                margin: 0 auto;

                @include sm {
                    width: 9rem;
                }
            }

            label {
                margin-top: 10px;
                display: inline-block;

                @include sm {
                    width: 100%;
                }
            }

            .label-container {
                display: flex;
                align-items: center;

                > span {
                    width: 80px;
                    text-align: left;
                }

                .input-container {
                    position: relative;

                    @include sm {
                        width: 100%;
                    }
                }

                .input {
                    border-bottom: 1px solid #D8D8D8;
                    width: 494px;
                    max-width: 100%;
                    font-size: 14px;
                    padding: 2px;
                    height: 44px;
                    position: relative;

                    & + .after {
                        content: '';
                        position: absolute;
                        display: block;
                        z-index: 1;
                        width: 100%;
                        bottom: 0;
                        height: 1px;
                        background: darken(#fed900, 5%);
                        transform: scale(0, 1);
                        transform-origin: left center;
                        transition: .3s linear;
                        opacity: 0;
                    }

                    &:focus + .after {
                        opacity: 1;
                        transform: scale(1, 1);
                    }

                    @include md {
                        width: 320px;
                    }

                    @include sm {
                        width: 100%;
                    }
                }
            }


            select {
                border-bottom: 1px solid #D8D8D8;
                width: 560px;
                font-size: 14px;
                padding: 2px;
                height: 44px;

                option {
                    margin-left: 20px;

                    padding-left: 50px;
                }
            }

            .Apply {
                width: 128px;
                height: 36px;
                background: rgba(250, 210, 20, 1);
                line-height: 32px;
                text-align: center;
                margin: 36px auto 0;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                cursor: pointer;

            }


        }
    }

    .Reservedsquare {
        position: relative;

        img {
            position: absolute;
            right: 287px;
            top: 10px;
        }

        .pf {
            position: absolute;
            right: 326px;
            top: 8px;
        }
    }

    .Booth {
        position: relative;

        img {
            position: absolute;
            right: 287px;
            top: 10px;

        }
    }

    .VerificationCode {
        position: relative;

        .btn {
            @include validateBtn();
        }
    }

    .showborder {
        border-bottom: 1px solid red !important;
        z-index: 1;
    }
</style>