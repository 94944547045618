import axios from "@/utils/axios"

//参观登记
export function registerAudience({
    channel,
    code,
    email,
    expo,
    lang,
    mobile,
    name
}) {
    return axios({
        url: 'api/public/expoRegister/Audience',
        method: 'post',
        data: {
            channel,
            code,
            email,
            expo,
            lang,
            mobile,
            name
        }
    })
}
//获取验证码
export function registersendCode({
    code,
    target,
    time,
    type,
    key
}) {
    return axios({
        url: '/api/public/expoRegister/sendCode',
        method: 'post',
        data: {
            code,
            target,
            time,
            type,
            key
        }
    })
}