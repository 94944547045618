var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Buyer" },
    [
      _c("BannerBuyServer"),
      _c("Registration", { attrs: { id: "RegistrationOfExhibitors" } }),
      _c("Scope", { attrs: { id: "ScopeOfExhibition" } }),
      _c("Exhibitorsdirectory", { attrs: { id: "ExhibitorsDirectory" } }),
      _c("Transportations", { attrs: { id: "TransportationAccommodation" } }),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }